import * as S from "./styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import walletIcon from "../../../../assets/images/walletSecondary.svg";
import fiveLines from "../../../..//assets/images/fiveLinesSecondary.svg";
import CalenderIconSecondary from "../../../../assets/images/CalenderIconSecondary.svg";
import sendIconSecondary from "../../../../assets/images/sendIconSecondary.svg";
import profileIconSecondary from "../../../../assets/images/profileIconSecondary.svg";
import ModalConfirm from "../modalConfirm";
import ModalSucess from "../modalSucess";
import logoPayhow from "../../../../assets/images/logoPayhow.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../../../services";
import { CircularProgress } from "@mui/material";

export function Mobile({ balanceData, loading }) {
  const navigate = useNavigate();

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalSucess, setOpenModalSucess] = useState(false);

  const [data, setData] = useState({ balanceData });
  const MySwal = withReactContent(Swal);

  function handleOpenModalSucess() {
    setOpenModalSucess(true);
    setOpenModalConfirm(false);
  }

  function viewModalConfirm() {
    if (balanceData.saldo_disponivel === "0.00") {
      MySwal.fire({
        icon: "error",
        title: "Erro!",
        text: "Sem saldo disponível para saque!",
      });
    } else {
      setOpenModalConfirm(true);
    }
  }

  function viewModalSucess() {
    setOpenModalSucess(true);
  }

  async function sendWithdrawRequest() {
    let body = {
      value: parseInt(balanceData.saldo_disponivel * 100),
    };
    try {
      const res = await api.post("v1/customers/withdraw", body);

      handleOpenModalSucess();
    } catch (err) {
      console.log(err);
    }
  }

  return loading ? (
    <S.ContainerLoading>
      <CircularProgress style={{ color: "#0092D4" }} />
    </S.ContainerLoading>
  ) : (
    <S.Container>
      <S.GroupHeader>
        <img src={walletIcon} width={40} alt="Icone Carteira" />
        <S.Text>Saldo disponível:</S.Text>
      </S.GroupHeader>
      <S.GroupInfo>
        <S.MonetaryValue>
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
          }).format(balanceData.saldo_disponivel)}
        </S.MonetaryValue>
        <S.TextInfoBottom>
          (Valores em análise:{" "}
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
          }).format(balanceData.saldo_pendente)}
          )
        </S.TextInfoBottom>
        <S.ButtonOutlineSecondary
          onClick={() => {
            viewModalConfirm();
          }}
        >
          Solicitar saque
        </S.ButtonOutlineSecondary>
        <S.TextInfoBottom>
          <a href="">Consultar nossas tarifas e prazos</a>
        </S.TextInfoBottom>
      </S.GroupInfo>
      <S.GroupButtons>
        <S.ButtonOutline
          onClick={() => {
            navigate("/extrato-financeiro");
          }}
        >
          <img src={fiveLines} width={40} alt="Icone Carteira" />

          <p>Extrato financeiro</p>
        </S.ButtonOutline>
        <S.ButtonOutline
          onClick={() => {
            navigate("/historico-de-transacoes");
          }}
        >
          <img src={CalenderIconSecondary} width={40} alt="Icone Carteira" />
          <p>Historico de transações</p>
        </S.ButtonOutline>
      </S.GroupButtons>

      {openModalConfirm ? (
        <ModalConfirm
          openModalConfirm={openModalConfirm}
          setOpenModalConfirm={setOpenModalConfirm}
          setOpenModalSucess={setOpenModalSucess}
          balanceData={balanceData}
          sendWithdrawRequest={sendWithdrawRequest}
        />
      ) : (
        ""
      )}

      {openModalSucess ? (
        <ModalSucess
          openModalSucess={openModalSucess}
          setOpenModalSucess={setOpenModalSucess}
        />
      ) : (
        ""
      )}
    </S.Container>
  );
}
