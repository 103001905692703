import * as S from "./style";
import Logo from "../../../../assets/images/Logo.png";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../../../services";

function WebPasswordRecovery() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const MySwal = withReactContent(Swal);

  async function handleSubmit(e) {
    setIsDisabled(true);
    e.preventDefault();
    try {
      const res = await api.post("v1/customers/password/reset", {
        email: username,
      });
      console.log(res);
      if (res.data != null) {
        MySwal.fire({
          icon: "success",
          title: "Senha alterada!",
          text: res.data.message,
          confirmButtonText: "OK!",
          preConfirm: () => {
            navigate("/");
          },
        });
      }

      if (res.data.error === `true`) {
        MySwal.fire({
          icon: "error",
          title: "Senha não alterada!",
          text: "Tente novamente...",
        });
      }
      setIsDisabled(false);
    } catch (err) {
      MySwal.fire({
        icon: "error",
        title: "Email inválido!",
        text: "Tente novamente...",
      });
      setIsDisabled(false);
    }
  }

  return (
    <S.Container>
      <S.BoxForm>
        <img alt="" src={Logo} />
        <S.SubBoxForm>
          <S.BoxTitles>
            <h4>Solicitar uma nova senha</h4>
            <p>
              Por favor informe o e-mail de cadastro para que possamos enviar
              uma nova senha temporária de acesso
            </p>
          </S.BoxTitles>
          <S.Form>
            <S.InputForm>
              <S.Label>E-mail</S.Label>
              <TextField
                size="small"
                placeholder="johndoe@gmail.com"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </S.InputForm>

            <S.BtnForm
              disable={isDisabled}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Enviar nova senha
            </S.BtnForm>

            <S.RegisterLabel>
              <S.Register
                onClick={() => {
                  navigate("/");
                }}
              >
                Voltar ao login
              </S.Register>
            </S.RegisterLabel>
          </S.Form>
        </S.SubBoxForm>
      </S.BoxForm>
    </S.Container>
  );
}

export default WebPasswordRecovery;
