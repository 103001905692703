import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import * as S from "./style";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "@media (min-width:768px)": {
    width: "50%",
  },
  width: "90%",
  bgcolor: "#FFF",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  padding: "20px",
};

export default function ModalConfirm({
  openModalConfirm,
  setOpenModalConfirm,
  sendWithdrawRequest,
  balanceData,
}) {
  const handleClose = () => setOpenModalConfirm(false);

  return (
    <div>
      <Modal
        open={openModalConfirm}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <S.Header>
            <S.Icon>
              <CloseIcon
                size={32}
                color="#333333"
                weight="bold"
                onClick={handleClose}
              />
            </S.Icon>

            <S.Title>Solicitação de saque</S.Title>
          </S.Header>
          <S.Content>
            <S.TitleSecondary>
              {`Tem certeza que deseja solicitar o saque no valor de  ${new Intl.NumberFormat(
                "pt-BR",
                {
                  style: "currency",
                  currency: "BRL",
                  minimumFractionDigits: 2,
                }
              ).format(balanceData.saldo_disponivel)} ?`}
            </S.TitleSecondary>

            <S.ContainerButton>
              <S.ButtonOutlineSecondary onClick={handleClose}>
                não, obrigado
              </S.ButtonOutlineSecondary>
              <S.ButtonOutline onClick={sendWithdrawRequest}>
                sim, tenho certeza
              </S.ButtonOutline>
            </S.ContainerButton>

            <S.Label>
              {`Esta operação fará uma cobrança no valor de${new Intl.NumberFormat(
                "pt-BR",
                {
                  style: "currency",
                  currency: "BRL",
                  minimumFractionDigits: 2,
                }
              ).format(
                balanceData.saldo_disponivel
              )} do saldo existente. Para maiores informações nossas tarifas e
              taxas`}
              <a href="www.google.com"> clicando aqui</a>
            </S.Label>
          </S.Content>
        </Box>
      </Modal>
    </div>
  );
}
