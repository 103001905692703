const enumPayment = (type) => {
  switch (type) {
    case "credit_card":
      return "Cartão de crédito";
    case "boleto":
      return "Boleto Bancário";
    case "pix":
      return "PIX";
    default:
      break;
  }
};

export { enumPayment };
