import * as S from './styles'

export function Button({ text, funcao }) {
    return (
        <S.Button onClick={() => {
            if (funcao) {
                funcao();
            }
        }}>
            <S.Text>{text}</S.Text>
        </S.Button>
    )
}