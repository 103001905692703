import * as S from "./style";
import Logo from "../../../../assets/images/Logo.png";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../../../services/index";

function WebLogin() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const MySwal = withReactContent(Swal);

  async function handleSubmit(e) {
    setIsDisabled(true);
    e.preventDefault();
    try {
      const res = await api.post("v1/customers/login/gateway", {
        email: username,
        password: password,
      });
      if (res.data != null) {
        localStorage.setItem("token", res?.data?.token);
        localStorage.setItem("id", res?.data?.companies[0]?.id);
        setTimeout(() => {
          window.location.href = `/home`;
        }, [1000]);
      }

      if (res.data.error === `true`) {
        MySwal.fire({
          icon: "error",
          title: "Login inválido!",
          text: "Tente novamente...",
        });
      }
      setIsDisabled(false);
    } catch (err) {
      MySwal.fire({
        icon: "error",
        title: "Login inválido!",
        text: "Tente novamente...",
      });
      setIsDisabled(false);
    }
  }

  return (
    <S.Container>
      <S.BoxForm>
        <img alt="" src={Logo} />
        <S.SubBoxForm>
          <S.BoxTitles>
            <h4>Bem vindo! 👋🏻</h4>
            <p>Por favor preencha seu e-mail e senha de acesso a sua conta</p>
          </S.BoxTitles>
          <S.Form>
            <S.InputForm>
              <S.Label>E-mail</S.Label>
              <TextField
                size="small"
                placeholder="johndoe@gmail.com"
                id="user"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </S.InputForm>
            <S.InputForm>
              <S.BoxLabel>
                <S.Label>Senha</S.Label>
                <S.BoxForgotPassword>
                  <p
                    onClick={() => {
                      navigate(`/password-recovery`);
                    }}
                  >
                    Esqueceu sua senha?
                  </p>
                </S.BoxForgotPassword>
              </S.BoxLabel>

              <TextField
                size="small"
                placeholder="⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </S.InputForm>

            <S.BtnForm onClick={(e) => handleSubmit(e)}>Acessar</S.BtnForm>
          </S.Form>
        </S.SubBoxForm>
      </S.BoxForm>
    </S.Container>
  );
}

export default WebLogin;
