import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import BankStatement from "./pages/BankStatement";
import MyProfile from "./pages/MyProfile";
import Login from "./pages/Login";
import PasswordRecovery from "./pages/PasswordRecovery";
import { Transaction } from "./pages/Transaction";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/password-recovery" element={<PasswordRecovery />} />
      <Route path="/home" element={<Home />} />
      <Route path="/historico-de-transacoes" element={<Transaction />} />
      <Route path="/extrato-financeiro" element={<BankStatement />} />
      <Route path="/meu-perfil" element={<MyProfile />} />
    </Routes>
  );
}
