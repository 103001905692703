import styled from "styled-components";

export const Container = styled.div``;

export const BoxImg = styled.div`
  width: 100%;
  height: 30vh;
  z-index: 1;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
`;

export const BoxForm = styled.div`
  z-index: 99;
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 72vh;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px -6px 7px 0px #8a8a8a4d;
  margin-top: -20px;

  img {
    margin: 40px 0px 10px 0px;
  }
`;

export const SubBoxForm = styled.div`
  padding: 30px 25px 25px 25px;
`;

export const BoxTitles = styled.div`
  h4 {
    font-size: 24px;
    font-weight: 500;
    color: #5e5873;
    padding: 10px 0px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    color: #6e6b7b;
    padding: 10px 0px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
`;

export const BoxLabel = styled.div`
  display: flex;
`;

export const Label = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 300;
  color: #585858;
  margin-bottom: 5px;
`;

export const BoxForgotPassword = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  p {
    color: #f50272;
    font-size: 14px;
  }
`;

export const BtnForm = styled.button`
  background-color: #0092d4;
  border: none;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  margin: 20px 0px;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
`;

export const RegisterLabel = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 300;
  color: #585858;
  margin-bottom: 5px;
  text-align: center;
`;

export const Register = styled.span`
  color: #f50272;
  font-size: 1rem;
`;
