import DesktopLayout from "../../layouts/DesktopLayout";
import MobileLayout from "../../layouts/MobileLayout";
import { Mobile } from './components/mobile'
import { Web } from './components/web'
import * as S from "./style";


export function Transaction() {
  let width = window.screen.width;

  return width <= 768 ? (
    <MobileLayout>
      <Mobile />
    </MobileLayout>
  ) : (
    <DesktopLayout>
      <S.Text>
        <Web />

      </S.Text>
    </DesktopLayout>
  );
}

