import styled from "styled-components";

export const Container = styled.div``;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 23px;
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProfileImage = styled.img`
  width: 113px;
  height: 113px;
  border-radius: 50%;
  position: relative;
`;

export const editImage = styled.img``;

export const EditWrapper = styled.div``;

export const EditLabel = styled.label`
  position: absolute;
  margin-top: 75px;
  margin-left: -38px;
  cursor: pointer;
`;

export const EditInput = styled.input`
  display: none;
`;

export const Form = styled.form`
  .MuiTextField-root {
    margin-bottom: 20px;
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 7px;
  color: #333333;
  font-size: 18px;
`;

export const Input = styled.input`
  width: 100%;
  height: 38px;
  padding: 8px 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
`;

export const ButtonWrapper = styled.div``;

export const Button = styled.button`
  width: 100%;
  height: 42px;
  background-color: #01b797;
  color: #fff;
  border-radius: 10px;
  border: none;
  font-size: 17px;
  font-weight: 500;
  padding: 10px 17px;
`;

export const OutlineButton = styled.button`
  width: 100%;
  height: 42px;
  background-color: #fff;
  color: #01b797;
  border-radius: 10px;
  border: 1px solid #01b797;
  font-size: 17px;
  font-weight: 500;
  padding: 10px 17px;
  margin-top: 30px;
  margin-bottom: 64px;
`;

export const PasswordButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  background-color: #fff;
  color: #0092d4;
  border-radius: 10px;
  border: 1px solid #0092d4;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 17px;
  margin-bottom: 30px;

  img {
    margin-right: 10px;
  }
`;
