import MobiPasswordRecovery from "./components/mobile";
import WebPasswordRecovery from "./components/web";

function PasswordRecovery() {
  let width = window.screen.width;

  return width <= 768 ? <MobiPasswordRecovery /> : <WebPasswordRecovery />;
}

export default PasswordRecovery;
