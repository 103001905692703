import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 52px;
  background-color: #fd5454;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 18px;
  margin-bottom: 10px;
`

export const Text = styled.p`
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;

  @media (min-width: 768px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 0;
  }

  img {
    margin-right: 15px;
  }
`

export const Span = styled.span`
  text-decoration: underline;
  cursor: pointer;
`