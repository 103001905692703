import styled from "styled-components";

export const Container = styled.label`
  display: block;
  margin: 20px;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 26px;
  color: #333;
`;

export const Text = styled.div`
  margin: 20px;

  h1 {
    color: #183273;
  }
`;

export const Label = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 300;
  color: #585858;
  margin-bottom: 5px;
`;

export const Header = styled.header`
  margin: 15px 0 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const HeaderButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 27px 0 10px 0;
`;

export const Select = styled.select`
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  margin-right: 20px;
`;
export const Input = styled.input`
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 10px;

  :focus {
    outline: none;
  }
`;

export const ButtonHeader = styled.button`
  background-color: #01b797;
  border: none;
  border-radius: 10px;
  width: 170px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  margin: 10px;
`;

export const TextTableCell = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ImgCloud = styled.img`
  padding-right: 5px;
`;

export const TimeFilter = styled.div`
  margin-right: 20px;
`;

export const TextIcon = styled.span`
  width: 120px;
  height: 20px;
  border-radius: 17px;
  padding: 1px 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  text-align: center;

  color: ${(props) =>
    props.status === "aprovado"
      ? "#28c76f"
      : props.status === "reprovado"
      ? "#E93E3E"
      : props.status === "estornado"
      ? "#E93E3E"
      : props.status === "cancelado"
      ? "#333333"
      : "#FAAB36"};
`;

export const Icon = styled.div`
  width: 60%;
  height: 20px;
  border-radius: 17px;
  padding: 1px 9px;
  background-color: ${(props) =>
    props.status === "aprovado"
      ? "#28c76f20"
      : props.status === "reprovado"
      ? "#E93E3E20"
      : props.status === "estornado"
      ? "#E93E3E20"
      : props.status === "cancelado"
      ? "#33333320"
      : "#FAAB3620"};
  display: flex;
  /* align-items: center; */
  justify-content: center;
`;
