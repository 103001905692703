import React, { createContext, useState, useMemo } from "react";
import { initialBankAccount } from "./types";

export const GlobalContext = createContext({});

export default function GlobalProvider({ children }) {
  const [bankAccount, setBankAccount] = useState(initialBankAccount)

  const providerValue = useMemo(() => ({
    bankAccount,
    setBankAccount
  }), [
    bankAccount,
    setBankAccount
  ])

  return (
    <GlobalContext.Provider
      value={providerValue}
    >
      {children}
    </GlobalContext.Provider>
  );
}


