import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Grid, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import editPencil from "../../../../../assets/images/edit-pencil.svg";
import lockOpen from "../../../../../assets/images/bx_lock-open.svg";

import InvalidTooltip from "../../Invalidtooltip";
import ModalPassword from "../../Modal";
import api from "../../../../../services";

import { initialDataForm } from "./types";
import {
  cpfMask,
  cnpjMask,
  phoneMask,
  zipcodeMask,
} from "../../../../../utils/masks";
import * as S from "./style";
import { getBase64 } from "../../../../../utils/getBase64";

export default function PersonalData() {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [dataForm, setDataForm] = useState(initialDataForm);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getProfileData();
  }, []);

  async function getProfileData() {
    try {
      const { data } = await api.get("v1/customers/profile");
      setDataForm({
        photo: data.photo,
        name: data.name,
        email: data.email,
        phone: data.phone,
        document: data.document,
        registration_status: data.registration_status,
        finance_status: data.finance_status,
        address: {
          zip_code: data.address.zip_code,
          state: data.address.state,
          city: data.address.city,
          street: data.address.street,
          number: data.address.number,
          neighborhood: data.address.neighborhood,
          complement: data.address.complement,
        },
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  function handleChange(e, type) {
    const { name, value } = e.target;

    if (type === "address") {
      setDataForm((state) => ({
        ...state,
        address: {
          ...state.address,
          [name]: value,
        },
      }));
      return;
    }

    setDataForm((state) => ({ ...state, [name]: value }));
  }

  async function handlePhotoProfile(e) {
    const fileBase64 = await getBase64(e.target.files[0]);
    setDataForm((state) => ({ ...state, photo: fileBase64 }));
  }

  async function handleSaveData(e, type) {
    e.preventDefault();
    try {
      await api.put("v1/customers/profile", dataForm);
      getProfileData();
      MySwal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Informações salvas com sucesso!",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  function viewModal(e, childOpen) {
    e.preventDefault();
    if (childOpen) {
      setOpen(childOpen);
      return;
    }
    setOpen(open ? false : true);
  }

  return (
    <S.Container>
      {loading ? (
        <S.ContainerLoading>
          <CircularProgress />
        </S.ContainerLoading>
      ) : (
        <>
          {dataForm.registration_status !== "aprovado" ? (
            <InvalidTooltip />
          ) : null}

          <S.ImageWrapper>
            <S.ProfileImage src={dataForm.photo} alt="Icone Carteira" />

            <S.EditWrapper>
              <S.EditLabel htmlFor="file">
                <S.editImage src={editPencil} alt="edit pencil" />
              </S.EditLabel>
              <S.Input id="file" type="file" onChange={handlePhotoProfile} />
            </S.EditWrapper>
          </S.ImageWrapper>

          <S.Form>
            <S.Label>Nome Completo ou Razão Social:</S.Label>
            <TextField
              fullWidth
              name="name"
              onChange={handleChange}
              value={dataForm.name}
              size="small"
              placeholder="digite as informações..."
            />

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <S.Label>CNPJ ou CPF:</S.Label>
                <TextField
                  fullWidth
                  name="document"
                  onChange={handleChange}
                  value={
                    dataForm.document.length > 13
                      ? cnpjMask(dataForm.document)
                      : cpfMask(dataForm.document)
                  }
                  size="small"
                  placeholder="cpf ou cnpj..."
                />
              </Grid>
              <Grid item xs={3}>
                <S.Label>Telefone de contato:</S.Label>
                <TextField
                  fullWidth
                  name="phone"
                  onChange={handleChange}
                  value={phoneMask(dataForm.phone)}
                  size="small"
                  placeholder="contato..."
                />
              </Grid>
            </Grid>

            <S.Label>E-mail de cadastro:</S.Label>
            <TextField
              fullWidth
              name="email"
              onChange={handleChange}
              value={dataForm.email}
              size="small"
              placeholder="digite as informações..."
            />

            <Grid container>
              <Grid item xs={3}>
                <S.Label>CEP:</S.Label>
                <TextField
                  fullWidth
                  name="zip_code"
                  onChange={(e) => handleChange(e, "address")}
                  value={zipcodeMask(dataForm.address.zip_code)}
                  inputProps={{ maxLength: 9 }}
                  size="small"
                  placeholder="cep"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={9}>
                <S.Label>Endereço:</S.Label>
                <TextField
                  fullWidth
                  name="street"
                  onChange={(e) => handleChange(e, "address")}
                  value={dataForm.address.street}
                  size="small"
                  placeholder="endereço"
                />
              </Grid>
              <Grid item xs={3}>
                <S.Label>Número:</S.Label>
                <TextField
                  fullWidth
                  name="number"
                  type="number"
                  onChange={(e) => handleChange(e, "address")}
                  value={dataForm.address.number}
                  size="small"
                  placeholder="numero"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={8}>
                <S.Label>Bairro:</S.Label>
                <TextField
                  fullWidth
                  name="neighborhood"
                  onChange={(e) => handleChange(e, "address")}
                  value={dataForm.address.neighborhood}
                  size="small"
                  placeholder="digite seu bairro..."
                />
              </Grid>
              <Grid item xs={4}>
                <S.Label>Complemento:</S.Label>
                <TextField
                  fullWidth
                  name="complement"
                  onChange={(e) => handleChange(e, "address")}
                  value={dataForm.address.complement}
                  size="small"
                  placeholder="digite seu complemento..."
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <S.Label>Cidade:</S.Label>
                <TextField
                  fullWidth
                  name="city"
                  onChange={(e) => handleChange(e, "address")}
                  value={dataForm.address.city}
                  size="small"
                  placeholder="digite sua cidade..."
                />
              </Grid>
              <Grid item xs={3}>
                <S.Label>Estado:</S.Label>
                <TextField
                  fullWidth
                  name="state"
                  onChange={(e) => handleChange(e, "address")}
                  value={dataForm.address.state}
                  size="small"
                  placeholder="digite seu estado..."
                />
              </Grid>
            </Grid>

            <S.ButtonWrapper>
              <div>
                <S.Button onClick={handleSaveData}>Salvar informações</S.Button>
                <S.OutlineButton onClick={() => navigate("/home")}>
                  Cancelar
                </S.OutlineButton>
              </div>
              <S.PasswordButton onClick={viewModal}>
                <img src={lockOpen} alt="lock open" />
                alterar senha de acesso
              </S.PasswordButton>
            </S.ButtonWrapper>
          </S.Form>
        </>
      )}

      {open ? <ModalPassword open={open} funcao={viewModal} /> : ""}
    </S.Container>
  );
}
