import React, { createContext, useContext, useEffect, useState } from "react";

export const DeviceContext = createContext({});

export function useDevice() {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error("useDevice must be used inside DeviceProvider");
  }

  return context;
}

export function DeviceProvider(props) {
  const { children } = props;

  const [isMobileDevice, setIsMobileDevice] = useState(false);

  const handleIsMobile = (value) => {
    setIsMobileDevice(value);
  };

  const handleResize = () => {
    if (window.innerWidth <= 499 && isMobileDevice === false)
      handleIsMobile(true);
    else if (window.innerWidth >= 500 && isMobileDevice === true)
      handleIsMobile(false);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileDevice]);

  return (
    <DeviceContext.Provider
      value={{
        isMobileDevice,
        handleIsMobile,
        setIsMobileDevice,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
}
