import { BrowserRouter } from "react-router-dom/dist";
import Router from "./routes";
import ContextProvider from "./context/GlobalContext";
import { DeviceProvider } from "./context/deviceContext";

function App() {
  return (
    <>
      <DeviceProvider>
        <ContextProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </ContextProvider>
      </DeviceProvider>
    </>
  );
}

export default App;
