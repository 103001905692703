import { useContext } from "react";

import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";

import PersonalData from "./PersonalData";
import BankData from "./BankData";
import { GlobalContext } from "../../../../context/GlobalContext";

import * as S from "./style";

const Tab = styled(TabUnstyled)`
  color: #333333;
  font-size: 1rem;
  font-weight: bold;
  padding: 6px 17px;
  border: none;
  background-color: #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
  cursor: pointer;

  &.${tabUnstyledClasses.selected} {
    border-bottom: 2px solid #0092d4;
  }
`;

const TabPanel = styled(TabPanelUnstyled)``;

const TabsList = styled(TabsListUnstyled)`
  display: flex;
  margin-top: 3rem;
  margin-bottom: 2rem;
  gap: 30px;
`;

export default function Web() {
  const { bankAccount } = useContext(GlobalContext);

  return (
    <TabsUnstyled defaultValue={0}>
      <S.Header>
        <S.Title>Meu perfil</S.Title>
        <S.DataWrapper>
          <div>
            <p>Agência:</p>
            <p>{bankAccount.agency}</p>
          </div>

          <div>
            <p>Conta:</p>
            <p>{bankAccount.account}</p>
          </div>
        </S.DataWrapper>
      </S.Header>

      <TabsList>
        <Tab>Dados pessoais</Tab>
        <Tab>Dados bancários</Tab>
      </TabsList>
      <TabPanel value={0}>
        <PersonalData />
      </TabPanel>
      <TabPanel value={1}>
        <BankData />
      </TabPanel>
    </TabsUnstyled>
  );
}
