import DesktopLayout from "../../layouts/DesktopLayout";
import MobileLayout from "../../layouts/MobileLayout";
import { Mobile } from "./components/mobile";
import { Web } from "./components/web";
import * as S from "./style";
import api from "../../services/index";
import { useEffect, useState } from "react";

export function Home() {
  let width = window.screen.width;

  const [balanceData, setBalanceData] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getBalanceInfo();
  }, []);

  async function getBalanceInfo() {
    try {
      setLoading(true);
      const { data } = await api.get("/v1/customers/account/balance");
      setBalanceData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  return width <= 768 ? (
    <MobileLayout>
      <Mobile balanceData={balanceData} loading={loading} />
    </MobileLayout>
  ) : (
    <DesktopLayout>
      <S.Text>
        <Web balanceData={balanceData} loading={loading} />
      </S.Text>
    </DesktopLayout>
  );
}
