import styled from "styled-components";
import { theme } from "../../assets/styles/theme";

export const Button = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 10px;
  background-color: ${theme.colors.primary};
  border: none;
`;

export const Text = styled.span`
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  color: #fff;
`;
