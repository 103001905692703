import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  body {
    font-size: 16px;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  button {
    cursor: pointer;
  }
`;
