import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as S from "./styles";
import * as React from "react";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { IconButton, TextField } from "@mui/material";
import Menu from "@mui/material/Menu";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import MenuItem from "@mui/material/MenuItem";
import ModalDetails from "../modalDetails";
import api from "../../../../services";
import CircularProgress from "@mui/material/CircularProgress";
import { enumPayment } from "../../../../utils/functions";

export function Web() {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [transactions, setTransactions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState("");
  const [body, setBody] = React.useState({
    status: "",
    start: "",
    end: "",
  });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function openModalDetails(row) {
    setData(row);
    setModalIsOpen(true);
    setAnchorEl(null);
  }

  async function getTransactions() {
    try {
      setLoading(true);
      const res = await api.post("/v1/customers/transaction/history", body);
      setTransactions(res.data.data);
      console.log(res.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  React.useEffect(() => {
    getTransactions();
  }, [body]);

  return (
    <S.Container>
      <S.Title>Histórico de transações</S.Title>
      <S.Header>
        <S.TimeFilter>
          <S.Label>Data Inicial</S.Label>
          <TextField
            id="date"
            type="date"
            onChange={(e) => setBody({ ...body, start: e.target.value })}
            value={body?.start}
            inputProps={{
              style: {
                padding: 10,
                height: 20,
                marginRight: 10,
              },
            }}
          />
        </S.TimeFilter>
        <S.TimeFilter>
          <S.Label>Data Final</S.Label>
          <TextField
            id="date"
            type="date"
            onChange={(e) => setBody({ ...body, end: e.target.value })}
            value={body?.end}
            inputProps={{
              style: {
                padding: 10,
                height: 20,
                marginRight: 10,
              },
            }}
          />
        </S.TimeFilter>
        <S.TimeFilter>
          <S.Label>Status</S.Label>
          <S.Select
            onChange={(e) => setBody({ ...body, status: e.target.value })}
          >
            <option value="" selected>
              Todos
            </option>
            <option value="aprovado">aprovado</option>
            <option value="reprovado">reprovado</option>
            <option value="cancelado">cancelado</option>
            <option value="pendente">pendente</option>
            <option value="estornado">estornado</option>
          </S.Select>
        </S.TimeFilter>
      </S.Header>
      <S.Content>
        <TableContainer component={Paper}>
          {loading ? (
            <S.ContainerLoading>
              <CircularProgress />
            </S.ContainerLoading>
          ) : (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#B2B2B2" }}>
                <TableRow>
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    Data
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    Detalhes
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    Status
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    Valor
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {transactions.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {row.date}
                    </TableCell>
                    <TableCell align="center">
                      {`Transação: ${row?.transaction_code} /  ${enumPayment(
                        row?.payment_type
                      )}  | Taxa: ${new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                      }).format(parseFloat(row?.tax_amount))}`}
                    </TableCell>
                    <TableCell align="center">
                      <S.TextTableCell>
                        {row.status === "aprovado" ? (
                          <S.IconGreen>
                            <S.TextIconGreen>{row.status}</S.TextIconGreen>
                          </S.IconGreen>
                        ) : row.status === "reprovado" ? (
                          <S.IconRed>
                            <S.TextIconRed>{row.status}</S.TextIconRed>
                          </S.IconRed>
                        ) : row.status === "cancelado" ? (
                          <S.IconBlack>
                            <S.TextIconBlack>{row.status}</S.TextIconBlack>
                          </S.IconBlack>
                        ) : row.status === "pendente" ? (
                          <S.IconYellow>
                            <S.TextIconYellow>{row.status}</S.TextIconYellow>
                          </S.IconYellow>
                        ) : row.status === "estornado" ? (
                          <S.IconBlack>
                            <S.TextIconBlack>{row.status}</S.TextIconBlack>
                          </S.IconBlack>
                        ) : (
                          ""
                        )}
                      </S.TextTableCell>
                    </TableCell>
                    <TableCell align="center">R$ {row.amount}</TableCell>
                    <TableCell
                      align="center"
                      onClick={() => openModalDetails(row)}
                    >
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        style={{ padding: 0 }}
                      >
                        <MoreVertOutlinedIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem>
                          <ZoomInOutlinedIcon style={{ marginRight: 5 }} />
                          Detalhes
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <ModalDetails
          setModalIsOpen={setModalIsOpen}
          modalIsOpen={modalIsOpen}
          data={data}
        />
      </S.Content>
    </S.Container>
  );
}
