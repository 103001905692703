import DesktopLayout from "../../layouts/DesktopLayout";
import MobileLayout from "../../layouts/MobileLayout";
import Mobile from "./components/Mobile"
import Web from "./components/Web";

export default function MyProfile() {
  let width = window.screen.width;

  return width <= 768 ? (
    <MobileLayout>
      <Mobile />
    </MobileLayout>
  ) : (
    <DesktopLayout>
      <Web />
    </DesktopLayout>
  );
}

