import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Funnel } from "phosphor-react";
import { useEffect, useState } from "react";
import api from "../../../../services";
import { enumPayment } from "../../../../utils/functions";
import ModalFilter from "./components/modal";
import * as S from "./style";

export function Mobile() {
  const [open, setOpen] = useState(false);
  const [statement, setStatement] = useState([]);

  const [body, setBody] = useState({
    status: "",
    start: "",
    end: "",
  });

  async function getData() {
    const res = await api.post(`/v1/customers/finance`, body);
    setStatement(res.data.data);
  }

  function ViewModal() {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <S.Container>
      <S.HeaderAndFilter>
        <S.Title>Extrato financeiro</S.Title>
        <S.ContainerFilter>
          <Funnel
            size={25}
            color="#FFF"
            weight="bold"
            onClick={() => {
              setOpen(true);
            }}
          />
          <S.TextFilter>Filtrar</S.TextFilter>
        </S.ContainerFilter>
      </S.HeaderAndFilter>
      <S.Content>
        {statement.map((statement) => (
          <S.ContainerAccordion>
            <Accordion>
              <AccordionSummary
                expandIcon={
                  statement.status !== "aprovado" &&
                  statement.status !== "pendente" ? (
                    <ExpandMoreIcon />
                  ) : (
                    ""
                  )
                }
              >
                <S.HeaderAccordion>
                  <S.TitleAccordion>
                    {statement?.date
                      ? statement.date
                      : statement.transaction_date}
                  </S.TitleAccordion>
                  <S.Text>Descrição:</S.Text>
                  <S.InformationsAccordion>
                    {statement?.type == "vendas"
                      ? `Transação: ${
                          statement?.transaction_code
                        } /  ${enumPayment(
                          statement?.payment_type
                        )} | Taxa de transação: ${new Intl.NumberFormat(
                          "pt-BR",
                          {
                            style: "currency",
                            currency: "BRL",
                            minimumFractionDigits: 2,
                          }
                        ).format(parseFloat(statement?.tax_amount))}`
                      : null}
                    {statement?.type == "saques"
                      ? `Descrição: ${statement?.observation} | Número da conta de saque:  ${statement?.bank_account?.account}`
                      : null}
                    {statement?.type == "débitos"
                      ? `Descrição: ${statement?.observation}`
                      : null}
                  </S.InformationsAccordion>
                  <S.IconWrapper status={statement.status}>
                    <S.Value status={statement.status} value={statement.amount}>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                      }).format(parseFloat(statement.amount))}
                    </S.Value>
                    <S.Icon status={statement.status}>
                      <S.TextIcon status={statement.status}>
                        {statement.status}
                      </S.TextIcon>
                    </S.Icon>
                  </S.IconWrapper>
                </S.HeaderAccordion>
              </AccordionSummary>
              {statement.status !== "aprovado" &&
              statement.status !== "pendente" ? (
                <AccordionDetails>
                  <S.TextAccordion>
                    Motivo: <br />
                    {statement?.decline_message}
                  </S.TextAccordion>
                </AccordionDetails>
              ) : (
                ""
              )}
            </Accordion>
          </S.ContainerAccordion>
        ))}
      </S.Content>
      {open ? (
        <ModalFilter
          open={open}
          funcao={ViewModal}
          body={body}
          setBody={setBody}
          getData={getData}
        />
      ) : (
        ""
      )}
    </S.Container>
  );
}
