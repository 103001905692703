import { Funnel } from "phosphor-react";
import * as S from "./styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModalFilter from "../modalFilter";
import api from "../../../../services";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { enumPayment } from "../../../../utils/functions";

export function Mobile() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [body, setBody] = useState({
    status: "",
    start: "",
    end: "",
  });

  function ViewModal() {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  async function getTransactions() {
    try {
      setLoading(true);
      const res = await api.post("/v1/customers/transaction/history", body);
      setTransactions(res.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <S.Container>
      <S.HeaderAndFilter>
        <S.Title>Transações</S.Title>
        <S.ContainerFilter>
          <Funnel
            size={25}
            color="#FFF"
            weight="bold"
            onClick={() => {
              setOpen(true);
            }}
          />
          <S.TextFilter>Filtrar</S.TextFilter>
        </S.ContainerFilter>
      </S.HeaderAndFilter>
      {loading ? (
        <S.ContainerLoading>
          <CircularProgress />
        </S.ContainerLoading>
      ) : (
        <S.Content>
          {transactions.map((item) => (
            <S.ContainerAccordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <S.HeaderAccordion>
                    <S.TitleAccordion>
                      {`Transação: ${item?.transaction_code} /  ${enumPayment(
                        item?.payment_type
                      )}  | Taxa: ${new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                      }).format(parseFloat(item?.tax_amount))}`}
                    </S.TitleAccordion>
                    <S.InformationsAccordion>
                      Valor Total: R${item.amount}
                    </S.InformationsAccordion>
                    <S.InformationsAccordion>
                      {item.date}
                    </S.InformationsAccordion>
                    {item.status === "aprovado" ? (
                      <S.IconGreen>
                        <S.TextIconGreen>{item.status}</S.TextIconGreen>
                      </S.IconGreen>
                    ) : item.status === "reprovado" ? (
                      <S.IconRed>
                        <S.TextIconRed>{item.status}</S.TextIconRed>
                      </S.IconRed>
                    ) : item.status === "cancelado" ? (
                      <S.IconBlack>
                        <S.TextIconBlack>{item.status}</S.TextIconBlack>
                      </S.IconBlack>
                    ) : item.status === "pendente" ? (
                      <S.IconYellow>
                        <S.TextIconYellow>{item.status}</S.TextIconYellow>
                      </S.IconYellow>
                    ) : item.status === "estornado" ? (
                      <S.IconBlack>
                        <S.TextIconBlack>{item.status}</S.TextIconBlack>
                      </S.IconBlack>
                    ) : (
                      ""
                    )}
                  </S.HeaderAccordion>
                </AccordionSummary>
                <AccordionDetails>
                  <S.ContainerInformations>
                    <S.BoldInformation>
                      Nome do comprador: {item?.client.client_name}
                    </S.BoldInformation>
                    <S.InformationsAccordion>
                      CPF: {item?.client.client_document}
                    </S.InformationsAccordion>
                    <S.InformationsAccordion>
                      Telefone: {item?.client.client_phone}
                    </S.InformationsAccordion>
                    <S.InformationsAccordion>
                      E-mail: {item?.client.client_email}
                    </S.InformationsAccordion>
                    <S.InformationsAccordion>
                      Endereço: {item?.client.address?.street}
                    </S.InformationsAccordion>
                    <S.InformationsAccordion>
                      Cidade: {item?.client.address?.city}
                    </S.InformationsAccordion>
                    <S.InformationsAccordion>
                      Bairro: {item?.client.address?.neighborhood}
                    </S.InformationsAccordion>
                    {!!item?.client.credit_card ? (
                      <>
                        <S.InformationsAccordion>
                          Cartão: **** **** ****{" "}
                          {item?.client.credit_card?.lastFourDigits}
                        </S.InformationsAccordion>
                        <S.InformationsAccordion>
                          Bandeira Cartão: {item?.client.credit_card?.brand}
                        </S.InformationsAccordion>
                      </>
                    ) : null}
                  </S.ContainerInformations>
                </AccordionDetails>
              </Accordion>
            </S.ContainerAccordion>
          ))}
        </S.Content>
      )}
      {open ? (
        <ModalFilter
          open={open}
          funcao={ViewModal}
          body={body}
          setBody={setBody}
          getTransactions={getTransactions}
        />
      ) : (
        ""
      )}
    </S.Container>
  );
}
