import styled from 'styled-components'

export const Container = styled.div`
    margin: 20px;
`

export const Text = styled.p`
    font-weight: 500;
    color: #333333; 
    font-size: 20px;
    padding: 15px;
`
export const MonetaryValue = styled.p`
    font-weight: 500;
    color: #0092D4; 
    font-size: 65px;
`
export const TextInfoBottom = styled.p`
    font-weight: 500;
    color: #333333; 
    font-size: 16px;
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonOutline = styled.button`
    display: flex;
    flex-direction: column;
    border: 2px solid #0092D4;
    border-radius: 10px;
    color: #0092D4;
    background: none;
    margin: 20px;
    padding: 10px;
    height: 80%;

    p {
       margin-top: 10px;
       font-size: 16px 
    }
`
export const ButtonOutlineSecondary = styled.button`
    border: 2px solid #F50272;
    border-radius: 10px;
    color: #F50272;
    background: none;
    height: 50px;
    width: 50%;
    font-size: 16px;
    font-weight: 500;
    margin: 15px 0px;
`

export const GroupButtons = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    margin: 20px;
`

export const GroupHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const GroupInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    p {
        padding: 10px
    }
`