import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  padding: 23px 40px 25px 50px;
  margin-bottom: 27px;
  border-radius: 5px;
`;
export const ContainerLoading = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ImageWrapper = styled.div`
  margin-bottom: 23px;
`;

export const ProfileImage = styled.img`
  width: 113px;
  height: 113px;
  border-radius: 50%;
  position: relative;
`;

export const editImage = styled.img``;

export const EditWrapper = styled.div``;

export const EditLabel = styled.label`
  position: absolute;
  margin-top: -40px;
  margin-left: 75px;
  cursor: pointer;
`;

export const Input = styled.input`
  display: none;
`;

export const Form = styled.form`
  margin-bottom: 27px;

  .MuiTextField-root {
    margin-bottom: 20px;
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 7px;
  color: #6e6b7b;
  font-size: 13px;
  font-weight: 400;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`;

export const Button = styled.button`
  width: 200px;
  height: 42px;
  background-color: #01b797;
  color: #fff;
  border-radius: 10px;
  border: none;
  font-size: 17px;
  font-weight: 500;
  padding: 10px 17px;
`;

export const OutlineButton = styled.button`
  width: 200px;
  height: 42px;
  background-color: #fff;
  color: #01b797;
  border-radius: 10px;
  border: 1px solid #01b797;
  font-size: 17px;
  font-weight: 500;
  padding: 10px 17px;
  margin-left: 16px;
`;

export const PasswordButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  background-color: #fff;
  color: #0092d4;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 17px;
  text-decoration: underline;

  img {
    margin-right: 10px;
  }
`;
