import styled from "styled-components";
import { TextField } from "@mui/material";

export const Icon = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Title = styled.span`
  font-weight: 500;
  font-size: 20px;
  color: #333;
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
`;
export const Input = styled(TextField)`
  width: 100%;
`;

export const LabelAndInput = styled.div`
  margin: 15px 0px 10px 0px;
`;

export const ContainerButton = styled.div`
  margin-top: 20px;
`;
