import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";

import { TextField, Grid, Autocomplete } from "@mui/material";
import { cpfMask, cnpjMask } from "../../../../../utils/masks";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import api from "../../../../../services";
import * as S from "./style";

export default function BankData() {
  const MySwal = withReactContent(Swal);
  const { bankAccount, setBankAccount } = useContext(GlobalContext);

  const [banks, setBanks] = useState([]);
  console.log(bankAccount);
  useEffect(() => {
    getBanks();
  }, []);

  async function getBanks() {
    try {
      const { data } = await api.get("v1/customers/bank/list");
      setBanks(data);
    } catch (error) {
      console.log(error);
    }
  }

  function handleBankSelect(bank) {
    if (!bank) return;
    setBankAccount((state) => ({ ...state, bank_id: bank.id }));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setBankAccount((state) => ({ ...state, [name]: value }));
  }

  async function handleSaveButton(e) {
    e.preventDefault();
    try {
      await api.put("v1/customers/bank_account", bankAccount);
      MySwal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Dados bancarios salvos com sucesso!",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  console.log("bankAccount", bankAccount);

  return (
    <S.Container>
      <S.Form>
        <S.Label>Banco:</S.Label>
        <Autocomplete
          disablePortal
          getOptionLabel={(option) => option.name || option.bank_name}
          getoptionselected={(option) => option.id}
          options={banks}
          noOptionsText="Banco não encontrado"
          onChange={(e, value) => handleBankSelect(value)}
          // defaultValue={bankAccount}
          renderInput={(params) => (
            <TextField {...params} placeholder="Pesquise o banco..." />
          )}
          size="small"
        />

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <S.Label>Agência:</S.Label>
            <TextField
              fullWidth
              size="small"
              name="agency"
              onChange={handleChange}
              value={bankAccount.agency}
              placeholder="dados da agência"
            />
          </Grid>
          <Grid item xs={3}>
            <S.Label>Conta Corrente:</S.Label>
            <TextField
              fullWidth
              size="small"
              name="account"
              onChange={handleChange}
              value={bankAccount.account}
              placeholder="conta corrente"
            />
          </Grid>
          <Grid item xs={3}>
            <S.Label>CPF ou CNPJ do titular da conta:</S.Label>
            <TextField
              fullWidth
              size="small"
              name="document"
              onChange={handleChange}
              value={
                bankAccount.document.length > 13
                  ? cnpjMask(bankAccount.document)
                  : cpfMask(bankAccount.document)
              }
              placeholder="cpf do titular"
            />
          </Grid>
        </Grid>

        <S.Label>Nome do titular:</S.Label>
        <TextField
          fullWidth
          size="small"
          name="holder_name"
          onChange={handleChange}
          value={bankAccount.holder_name}
          placeholder="digite o nome do titular da conta"
        />

        <S.ButtonWrapper>
          <S.Button onClick={handleSaveButton}>Salvar informações</S.Button>
          <S.OutlineButton>Cancelar</S.OutlineButton>
        </S.ButtonWrapper>
      </S.Form>
    </S.Container>
  );
}
