import * as React from "react";
import { useDevice } from "../../context/deviceContext";
import DesktopLayout from "../../layouts/DesktopLayout";
import MobileLayout from "../../layouts/MobileLayout";
import { Mobile } from "./components/mobile";
import { Web } from "./components/web";

export default function BankStatement() {
  const { isMobileDevice } = useDevice();
  let width = window.screen.width;

  return width <= 768 ? (
    <MobileLayout>
      <Mobile />
    </MobileLayout>
  ) : (
    <DesktopLayout>
      <Web />
    </DesktopLayout>
  );
}
