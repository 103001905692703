import { useState, useEffect } from "react";

import { CircularProgress, TextField } from "@mui/material";
import ModalPassword from "../../Modal";

import profilePhoto from "../../../../../assets/images/profilePhoto.png";
import lockOpen from "../../../../../assets/images/bx_lock-open.svg";
import editPencil from "../../../../../assets/images/edit-pencil.svg";
import { getBase64 } from "../../../../../utils/getBase64";
import { initialDataForm } from "../../Web/PersonalData/types";
import { useNavigate } from "react-router-dom";
import {
  cpfMask,
  phoneMask,
  cnpjMask,
  zipcodeMask,
} from "../../../../../utils/masks";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import * as S from "./style";
import api from "../../../../../services";
import InvalidTooltip from "../../Invalidtooltip";

export default function PersonalData() {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [dataForm, setDataForm] = useState(initialDataForm);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProfileData();
  }, []);

  async function getProfileData() {
    try {
      const { data } = await api.get("v1/customers/profile");
      setDataForm({
        photo: data.photo,
        name: data.name,
        email: data.email,
        phone: data.phone,
        document: data.document,
        registration_status: data.finance_status,
        finance_status: data.finance_status,
        address: {
          zip_code: data.address.zip_code,
          state: data.address.state,
          city: data.address.city,
          street: data.address.street,
          number: data.address.number,
          neighborhood: data.address.neighborhood,
          complement: data.address.complement,
        },
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  function handleChange(e, type) {
    const { name, value } = e.target;

    if (type === "address") {
      setDataForm((state) => ({
        ...state,
        address: {
          ...state.address,
          [name]: value,
        },
      }));
      return;
    }

    setDataForm((state) => ({ ...state, [name]: value }));
  }

  async function handlePhotoProfile(e) {
    const fileBase64 = await getBase64(e.target.files[0]);
    setDataForm((state) => ({ ...state, photo: fileBase64 }));
  }

  async function handleSaveData(e, type) {
    e.preventDefault();
    try {
      await api.put("v1/customers/profile", dataForm);
      getProfileData();
      MySwal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Informações salvas com sucesso!",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  function viewModal(e) {
    e.preventDefault();
    setOpen(open ? false : true);
  }

  return (
    <S.Container>
      {loading ? (
        <S.ContainerLoading>
          <CircularProgress />
        </S.ContainerLoading>
      ) : (
        <>
          {dataForm.registration_status !== "aprovado" ? (
            <InvalidTooltip />
          ) : null}

          <S.ImageWrapper>
            <S.ProfileImage src={dataForm.photo} alt="Icone Carteira" />

            <S.EditWrapper>
              <S.EditLabel htmlFor="file">
                <S.editImage src={editPencil} alt="edit pencil" />
              </S.EditLabel>
              <S.EditInput
                id="file"
                type="file"
                onChange={handlePhotoProfile}
              />
            </S.EditWrapper>
          </S.ImageWrapper>

          <S.Form>
            <S.Label htmlFor="name">Nome Completo ou Razão Social:</S.Label>
            <TextField
              fullWidth
              size="small"
              name="name"
              onChange={handleChange}
              value={dataForm.name}
              placeholder="digite as informações..."
            />

            <S.Label htmlFor="cnpj-or-cpf">CNPJ ou CPF:</S.Label>
            <TextField
              fullWidth
              size="small"
              name="document"
              onChange={handleChange}
              value={
                dataForm.document.length > 13
                  ? cnpjMask(dataForm.document)
                  : cpfMask(dataForm.document)
              }
              placeholder="cpf ou cnpj"
            />

            <S.Label htmlFor="phone">Telefone de contato:</S.Label>
            <TextField
              fullWidth
              size="small"
              name="phone"
              onChange={handleChange}
              value={phoneMask(dataForm.phone)}
              placeholder="contato"
            />

            <S.Label htmlFor="email">E-mail de cadastro:</S.Label>
            <TextField
              fullWidth
              size="small"
              name="email"
              onChange={handleChange}
              value={dataForm.email}
              placeholder="e-mail de cadastro"
            />

            <S.Label htmlFor="zipcode">CEP:</S.Label>
            <TextField
              fullWidth
              size="small"
              name="zip_code"
              onChange={(e) => handleChange(e, "address")}
              value={zipcodeMask(dataForm.address.zip_code)}
              inputProps={{ maxLength: 9 }}
              placeholder="cep"
            />

            <S.Label htmlFor="address">Endereço:</S.Label>
            <TextField
              fullWidth
              size="small"
              name="street"
              onChange={(e) => handleChange(e, "address")}
              value={dataForm.address.street}
              placeholder="digite seu endereço..."
            />

            <S.Label htmlFor="number">Número:</S.Label>
            <TextField
              fullWidth
              size="small"
              name="number"
              type="tel"
              onChange={(e) => handleChange(e, "address")}
              value={dataForm.address.number}
              placeholder="numero"
            />

            <S.Label htmlFor="neighborhood">Bairro:</S.Label>
            <TextField
              fullWidth
              size="small"
              name="neighborhood"
              onChange={(e) => handleChange(e, "address")}
              value={dataForm.address.neighborhood}
              placeholder="bairro"
            />

            <S.Label htmlFor="complement">Complemento:</S.Label>
            <TextField
              fullWidth
              size="small"
              name="complement"
              onChange={(e) => handleChange(e, "address")}
              value={dataForm.address.complement}
              placeholder="complemento"
            />

            <S.Label htmlFor="city">Cidade:</S.Label>
            <TextField
              fullWidth
              size="small"
              name="city"
              onChange={(e) => handleChange(e, "address")}
              value={dataForm.address.city}
              placeholder="sua cidade"
            />

            <S.Label htmlFor="state">Estado:</S.Label>
            <TextField
              fullWidth
              size="small"
              name="state"
              onChange={(e) => handleChange(e, "address")}
              value={dataForm.address.state}
              placeholder="seu estado"
              sx={{ marginBottom: "80px !important" }}
            />
            {/* transformar em select e pegar os UF dos estados na APi ibge */}

            <S.ButtonWrapper>
              <S.PasswordButton onClick={viewModal}>
                <img src={lockOpen} alt="lock open" />
                alterar senha de acesso
              </S.PasswordButton>
              <S.Button onClick={handleSaveData}>Salvar informações</S.Button>
              <S.OutlineButton onClick={() => navigate("/home")}>
                Cancelar
              </S.OutlineButton>
            </S.ButtonWrapper>
          </S.Form>
        </>
      )}
      {open ? <ModalPassword open={open} funcao={viewModal} /> : ""}
    </S.Container>
  );
}
