import styled from "styled-components";

export const Container = styled.div``;

export const Title = styled.span`
  font-weight: 700;
  font-size: 26px;
  color: #333;
`;

export const InputAndLabel = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 300;
  color: #585858;
  margin-bottom: 5px;
`;

export const Select = styled.select`
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #cacaca;
  padding: 10px;
  background-color: transparent;
`;
export const Input = styled.input`
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 10px;

  :focus {
    outline: none;
  }
`;

export const TextTableCell = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const DivRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SmallInput = styled.div`
  width: 100%;
  max-width: 200px;
`;

export const ContainerFilters = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const Content = styled.div`
  margin-top: 10px;
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconGreen = styled.div`
  width: 120px;
  height: 20px;
  border-radius: 17px;
  padding: 1px 9px;
  background-color: #e5f8ed;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextIconGreen = styled.span`
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #28c76f;
`;

export const IconYellow = styled.div`
  width: 120px;
  height: 20px;
  border-radius: 17px;
  padding: 1px 9px;
  background-color: #fef5e7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextIconYellow = styled.span`
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #faab36;
`;

export const IconBlack = styled.div`
  width: 120px;
  height: 20px;
  border-radius: 17px;
  padding: 1px 9px;
  background-color: #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextIconBlack = styled.span`
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #333333;
`;

export const IconRed = styled.div`
  width: 120px;
  height: 20px;
  border-radius: 17px;
  padding: 1px 9px;
  background-color: #fce8e8;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextIconRed = styled.span`
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #e93e3e;
`;

export const TimeFilter = styled.div`
  margin-right: 20px;
`;

export const Header = styled.header`
  margin: 15px 0 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;
