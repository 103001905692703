import { useEffect, useContext, useState } from "react";

import { useNavigate } from "react-router-dom";

import walletIcon from "../../assets/images/wallet.svg";
import fiveLines from "../../assets/images/fiveLines.svg";
import CalenderIcon from "../../assets/images/CalenderIcon.svg";
import circleDolarIcon from "../../assets/images/circleDolar.svg";
import exitIcon from "../../assets/images/exitIcon.svg";
import profileIcon from "../../assets/images/profileIcon.svg";
import sendIcon from "../../assets/images/sendIcon.svg";
import profilePhoto from "../../assets/images/profilePhoto.png";
import logoPayhow from "../../assets/images/logoPayhow.png";
import { GlobalContext } from "../../context/GlobalContext";
import api from "../../services";

import * as S from "./style";
import { CircularProgress } from "@mui/material";

function DesktopLayout({ children, title }) {
  const { bankAccount, setBankAccount } = useContext(GlobalContext);
  const [userPhoto, setUserPhoto] = useState();
  const [name, setName] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getBankInfos();
    getProfileData();
  }, []);

  async function getBankInfos() {
    try {
      const { data } = await api.get("v1/customers/bank_account");
      setBankAccount(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getProfileData() {
    try {
      setLoading(true);
      const { data } = await api.get("v1/customers/profile");
      setUserPhoto(data.photo);
      setName(data.name);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <S.Container>
      <S.Navbar>
        <S.Header>
          {loading ? (
            <S.ContainerLoading>
              <CircularProgress style={{ color: "#FFF" }} />
            </S.ContainerLoading>
          ) : (
            <>
              <S.ProfileImage src={userPhoto} alt="profile"></S.ProfileImage>
              <S.ProfileName>{name}</S.ProfileName>
            </>
          )}
        </S.Header>
        <S.ProfileData>
          <S.Items>
            <p>Agência: {bankAccount.agency}</p>
          </S.Items>
          <S.Items>
            <p>Conta: {bankAccount.account}</p>
          </S.Items>
        </S.ProfileData>
        <S.GroupItems>
          <S.MenuItems
            onClick={() => {
              navigate("/home");
            }}
          >
            <img src={walletIcon} width={25} alt="Icone Carteira" />
            <p>Meu saldo</p>
          </S.MenuItems>

          <S.MenuItems
            onClick={() => {
              navigate("/extrato-financeiro");
            }}
          >
            <img src={fiveLines} width={25} alt="Icone Carteira" />
            <p>Extrato financeiro</p>
          </S.MenuItems>

          <S.MenuItems
            onClick={() => {
              navigate("/historico-de-transacoes");
            }}
          >
            <img src={CalenderIcon} width={25} alt="Icone Carteira" />
            <p>Histórico de transações</p>
          </S.MenuItems>

          {/* <S.MenuItems
            onClick={() => {
              navigate("/historico-de-transacoes");
            }}
          >
            <img src={sendIcon} width={25} alt="Icone Carteira" />
            <p>Solicitações</p>
          </S.MenuItems> */}

          <S.MenuItems
            onClick={() => {
              navigate("/meu-perfil");
            }}
          >
            <img
              src={profileIcon}
              width={25}
              height={25}
              alt="Icone Carteira"
            />
            <p>Meu Perfil</p>
          </S.MenuItems>
          <S.MenuItems>
            <img src={circleDolarIcon} width={25} alt="Icone Carteira" />
            <p>Tarifas e Prazos</p>
          </S.MenuItems>
          <S.MenuItems
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={exitIcon} width={25} alt="Icone Carteira" />
            <p>Sair</p>
          </S.MenuItems>
        </S.GroupItems>
      </S.Navbar>

      <S.Content>
        <S.ContentHeader>
          <S.Title>{title}</S.Title>

          <img src={logoPayhow} alt="" width={200} />
        </S.ContentHeader>
        {children}
      </S.Content>
    </S.Container>
  );
}

export default DesktopLayout;
