import styled from "styled-components";
import { TextField } from "@mui/material";


export const Icon = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
`
export const Content = styled.div`
    display: flex;  
    flex-direction: column;
`

export const Header = styled.div`
    display: flex;
    flex-direction: row;
`
export const Title = styled.span`
    font-weight: 500;
    font-size: 25px;
    color: #333;
`
export const TitleSecondary = styled.p`
    font-weight: 500;
    color: #333333; 
    font-size: 25px;
    padding: 15px;
    text-align: center;
`

export const Label = styled.label`
    font-weight: 500;
    font-size: 16px;
    color: #636363;
    text-align: center;
`

export const ContainerButton = styled.div`
    margin: 20px 0px;
    display: flex;
    @media (max-width: 768px) {
    flex-direction: column;
    }
    flex-direction: row;
    align-items: center;
`
export const ButtonOutline = styled.button`
    border: 2px solid #01B797;
    border-radius: 10px;
    color: #01B797;
    background: none;
    height: 60px;
    width: 100%;
    font-size: 25px;
    font-weight: 500;
    margin: 15px 0px;
`
export const ButtonOutlineSecondary = styled.button`
    border: none;
    border-radius: 10px;
    color: #FFF;
    background-color: #01B797;
    height: 60px;
    width: 100%;
    font-size: 25px;
    font-weight: 500;
    margin: 15px 10px;
`