import { useEffect, useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { TextField } from "@mui/material";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import * as S from './style'
import api from "../../../../services";

export default function ModalPassword({ open, funcao }) {
  const MySwal = withReactContent(Swal)

  const [password, setPassword] = useState({ value: '', visible: false })
  const [newPassword, setNewPassword] = useState({ value: '', visible: false })
  const [confirmPassword, setConfirmPassword] = useState({ value: '', visible: false })
  const [errorMessage, setErrorMessage] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setError(false)
    }, 3500)
  }, [error])

  async function handleSave(e){
    if (newPassword.value !== confirmPassword.value) {
      setError(true)
      setErrorMessage('As senhas não conferem.')
      return
    }

    const body = {
      password: password.value,
      new_password: newPassword.value
    }

    try {
      await api.put('v1/customers/profile/password', body)
      MySwal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Senha alterada com sucesso!',
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })
      funcao(e, false)
    } catch (error) {
      if (error.response.data.errors.message.length) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage('Algo deu errado.')
      }

      setError(true)
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={funcao}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <S.Box>
          <S.Container>
            <S.Title>
              Alterar senha de acesso
              <CloseIcon size={32} color="#333333" weight="bold" onClick={funcao} />
            </S.Title>
            <S.Subtitle>
              Por favor insira os dados de acesso abaixo para atualizar sua
              senha de acesso.
            </S.Subtitle>

            <S.Form>
              <S.Label>Senha atual:</S.Label>
              <TextField
                fullWidth
                size="small"
                type={password.visible ? 'text' : 'password'}
                onChange={e => setPassword(state => ({ ...state, value: e.target.value }))}
                value={password.value}
                placeholder="digite sua senha atual de acesso"
                InputProps={{
                  endAdornment: <>
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setPassword(state => ({ ...state, visible: !state.visible }))}
                        edge="end"
                      >
                        {password.visible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  </>
                }}
              />

                
              <S.Label>Nova senha:</S.Label>
              <TextField
                fullWidth
                size="small"
                type={newPassword.visible ? 'text' : 'password'}
                onChange={e => setNewPassword(state => ({ ...state, value: e.target.value }))}
                value={newPassword.value}
                placeholder="digite sua nova senha"
                InputProps={{
                  endAdornment: <>
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setNewPassword(state => ({ ...state, visible: !state.visible }))}
                        edge="end"
                      >
                        {newPassword.visible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  </>
                }}
              />

              <S.Label>Confirme sua nova senha:</S.Label>
              <TextField
                fullWidth
                size="small"
                type={confirmPassword.visible ? 'text' : 'password'}
                onChange={e => setConfirmPassword(state => ({ ...state, value: e.target.value }))}
                value={confirmPassword.value}
                placeholder="confirme sua nova senha"
                InputProps={{
                  endAdornment: <>
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setConfirmPassword(state => ({ ...state, visible: !state.visible }))}
                        edge="end"
                      >
                        {confirmPassword.visible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  </>
                }}
              />

              {error && (
                <S.ErrorWrapper>
                  <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
                </S.ErrorWrapper>
              )}
            </S.Form>

            <S.ButtonWrapper>
              <S.Button onClick={handleSave}>
                Atualizar
              </S.Button>
              <S.OutlineButton onClick={(e) => funcao(e, false)}>
                Cancelar
              </S.OutlineButton>
            </S.ButtonWrapper>
          </S.Container>
        </S.Box>
      </Modal>
    </div>
  );
}
