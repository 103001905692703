import styled from "styled-components";
import Login from "../../../../assets/images/Login.png";

export const Container = styled.div`
  background-image: url(${Login});
  width: 100%;
  height: 100vh;
  background-size: cover;
`;

export const BoxForm = styled.div`
  background-color: #fff;
  width: 480px;
  height: 550px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 6px 0px #8a8a8a;
  position: relative;
  top: 25%;
  left: 15%;

  img {
    margin: 40px 0px 10px 0px;
  }
`;

export const SubBoxForm = styled.div`
  padding: 30px 25px 25px 25px;
`;

export const BoxTitles = styled.div`
  h4 {
    font-size: 24px;
    font-weight: 500;
    color: #5e5873;
    padding: 10px 0px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    color: #6e6b7b;
    padding: 10px 0px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
`;

export const Label = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 300;
  color: #585858;
  margin-bottom: 5px;
`;

export const BoxForgotPassword = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: -32px;
  p {
    color: #f50272;
    font-size: 14px;
  }
`;

export const BtnForm = styled.button`
  background-color: #0092d4;
  border: none;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  margin: 20px 0px;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
`;

export const RegisterLabel = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 300;
  color: #585858;
  margin-bottom: 5px;
  text-align: start;
`;

export const Register = styled.span`
  color: #f50272;
  font-size: 1rem;
`;
