import styled from "@emotion/styled";

export const Header = styled.div`
`

export const DataWrapper = styled.div`
  display: flex;
  margin-top: 13px;
  margin-bottom: 40px;
  gap: 32px;

  p {
    font-size: 14px;
    font-weight: 500;
    color: #0092d4;
  }
`

export const Title = styled.div`
  font-size: 25px;
  font-weight: 500;
  color: #333;
`