import infoIcon from "../../../../assets/images/info.svg";
import * as S from "./style";

export default function InvalidTooltip() {
  return (
    <S.Container>
      <S.Text>
        <img src={infoIcon} alt="info icon" />
        Seu cadastro contém informações não validadas pela equipe PAYHOW, para
        maiores informações &nbsp; <a href="#">clique aqui</a>
      </S.Text>
    </S.Container>
  );
}
