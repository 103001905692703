import styled from "styled-components";

export const Icon = styled.div`
  position: absolute;
  right: 25px;
  top: 35px;
  cursor: pointer;
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
`;

export const Text = styled.span`
  font-weight: 300;
  font-size: 1rem;
  line-height: 18px;
  color: #000000;
`;

export const ContainerTitle = styled.div`
  width: 100%;
  margin: 10px 0px 20px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DivRow = styled.div`
  margin: 12px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const IconGreen = styled.div`
  width: 120px;
  height: 20px;
  border-radius: 17px;
  padding: 1px 9px;
  background-color: #e5f8ed;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextIconGreen = styled.span`
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #28c76f;
`;

export const IconYellow = styled.div`
  width: 120px;
  height: 20px;
  border-radius: 17px;
  padding: 1px 9px;
  background-color: #fef5e7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextIconYellow = styled.span`
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #faab36;
`;

export const IconBlack = styled.div`
  width: 120px;
  height: 20px;
  border-radius: 17px;
  padding: 1px 9px;
  background-color: #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextIconBlack = styled.span`
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #333333;
`;

export const IconRed = styled.div`
  width: 120px;
  height: 20px;
  border-radius: 17px;
  padding: 1px 9px;
  background-color: #fce8e8;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextIconRed = styled.span`
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #e93e3e;
`;
