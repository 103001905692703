import styled from 'styled-components'

export const Container = styled.div`
    margin: 20px;
`

export const HeaderAndFilter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const Title = styled.span`
    font-weight: 700;
    font-size: 26px;
    color: #333;
`

export const ContainerFilter = styled.div`
    width: 45px;
    height: 45px;
    border-radius: 5px;
    background-color: #0092D4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
`

export const TextFilter = styled.span`
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #fff;
`

export const Content = styled.div`
    margin: 30px 0px;
`
export const ContainerAccordion = styled.div`
    margin: 20px 0px;
`

export const HeaderAccordion = styled.div`
    display: flex;
    flex-direction: column;
`

export const TitleAccordion = styled.span`
   color: #0092D4;
   font-weight: 700;
   font-size: 16px;
`

export const BoldInformation = styled.b`
   color: #333;
font-weight: 700;
font-size: 13px;
margin-top: 5px 
`

export const InformationsAccordion = styled.span`
color: #333;
font-weight: 500;
font-size: 12px;
margin-top: 5px
`

export const ContainerInformations = styled.div`
    display: flex;
    flex-direction: column;
`

export const ContainerLoading = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IconGreen = styled.div`
    width: 90px;
    height: 20px;
    border-radius: 17px;
    padding: 1px 9px;
    background-color: #e5f8ed;
    margin-top: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
`

export const TextIconGreen = styled.span`
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #28c76f;
`


export const IconYellow = styled.div`
    width: 90px;
    margin-top: 10px;
    height: 20px;
    border-radius: 17px;
    padding: 1px 9px;
    background-color: #fef5e7;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const TextIconYellow = styled.span`
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #FAAB36;
`


export const IconBlack = styled.div`
    width: 90px;
    margin-top: 10px;
    height: 20px;
    border-radius: 17px;
    padding: 1px 9px;
    background-color: #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const TextIconBlack = styled.span`
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #333333;
`


export const IconRed = styled.div`
    width: 90px;
    margin-top: 10px;
    height: 20px;
    border-radius: 17px;
    padding: 1px 9px;
    background-color: #fce8e8;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const TextIconRed = styled.span`
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #E93E3E;
`