import WebLogin from "./components/web";
import MobileLogin from "./components/mobile";

function Login() {
  let width = window.screen.width;

  return width <= 768 ? (
    <MobileLogin/>
  ) : (
    <WebLogin />
  );

}

export default Login;
