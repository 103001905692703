import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "../../../../components/Button";
import CloseIcon from "@mui/icons-material/Close";
import * as S from "./styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "#FFF",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  padding: "20px",
};

export default function ModalFilter({
  open,
  funcao,
  body,
  setBody,
  getTransactions,
}) {
  function handleSubmit() {
    getTransactions();
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={funcao}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <S.Header>
            <S.Icon>
              <CloseIcon
                size={32}
                color="#333333"
                weight="bold"
                onClick={funcao}
              />
            </S.Icon>

            <S.Title>Filtrar por período:</S.Title>
          </S.Header>
          <S.Content>
            <S.LabelAndInput>
              <S.Label>De</S.Label>
              <S.Input
                size="small"
                type="date"
                value={body?.start}
                onChange={(e) => setBody({ ...body, start: e.target.value })}
              />
            </S.LabelAndInput>
            <S.LabelAndInput>
              <S.Label>Até</S.Label>
              <S.Input
                size="small"
                type="date"
                value={body?.end}
                onChange={(e) => setBody({ ...body, end: e.target.value })}
              />
            </S.LabelAndInput>
            <S.LabelAndInput>
              <S.Label>Status</S.Label>
              <S.Select
                onChange={(e) => setBody({ ...body, status: e.target.value })}
              >
                <option value="" selected>
                  Todos
                </option>
                <option value="aprovado">aprovado</option>
                <option value="reprovado">reprovado</option>
                <option value="cancelado">cancelado</option>
                <option value="pendente">pendente</option>
                <option value="estornado">estornado</option>
              </S.Select>
            </S.LabelAndInput>
            <S.ContainerButton>
              <Button text="Filtrar" funcao={handleSubmit} />
            </S.ContainerButton>
          </S.Content>
        </Box>
      </Modal>
    </div>
  );
}
