import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  padding: 23px 40px 25px 50px;
  margin-bottom: 27px;
  border-radius: 5px;
  height: 700px;
`
export const Form = styled.form`
  margin-bottom: 27px;

  .MuiTextField-root {
    margin-bottom: 20px;
  }
`

export const Label = styled.label`
  display: block;
  margin-bottom: 7px;
  color: #6E6B7B;
  font-size: 13px;
  font-weight: 400;
`

export const ButtonWrapper = styled.div`
  margin-top: 331px;
`

export const Button = styled.button`
  width: 200px;
  height: 42px;
  background-color: #01b797;
  color: #fff;
  border-radius: 10px;
  border: none;
  font-size: 17px;
  font-weight: 500;
  padding: 10px 17px;
`

export const OutlineButton = styled.button`
  width: 200px;
  height: 42px;
  background-color: #fff;
  color: #01b797;
  border-radius: 10px;
  border: 1px solid #01b797;
  font-size: 17px;
  font-weight: 500;
  padding: 10px 17px;
  margin-left: 16px;
`