import * as React from "react";
import Box from "@mui/material/Box";
import * as S from "./style";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const style = {
  position: "absolute",
  borderRadius: "10px",
  width: `45%`,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,

  outline: `1px solid #d9d9d9`,
};

export default function ModalDetails({ modalIsOpen, setModalIsOpen, data }) {
  const handleClose = () => setModalIsOpen(false);

  return (
    <Modal
      open={modalIsOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <S.Icon>
          <CloseOutlinedIcon onClick={handleClose} />
        </S.Icon>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Detalhes da transação
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <S.ContainerTitle>
            <S.Text>Nome do comprador: {data?.client?.client_name}</S.Text>
          </S.ContainerTitle>

          <S.DivRow>
            <S.Text>CPF: {data?.client?.client_document}</S.Text>
            <S.Text>Telefone: {data?.client?.client_phone}</S.Text>
          </S.DivRow>
          <S.DivRow>
            <S.Text>E-mail: {data?.client?.client_email}</S.Text>

            {!!data?.client?.credit_card ? (
              <S.Text>
                Cartão: **** **** ****{" "}
                {data?.client?.credit_card?.lastFourDigits}
              </S.Text>
            ) : null}
          </S.DivRow>
        </Typography>
      </Box>
    </Modal>
  );
}
