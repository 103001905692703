import styled from "styled-components";

export const Icon = styled.div`
  position: absolute;
  right: 25px;
  top: 35px;
  cursor: pointer;
`;

export const Text = styled.span`
  font-weight: 300;
  font-size: 1rem;
  line-height: 18px;
  color: #000000;
`;

export const ContainerTitle = styled.div`
  margin-bottom: 20px;
`;

export const DivRow = styled.div`
  margin: 12px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
