import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import { IconButton, TextField } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDevice } from "../../../../context/deviceContext";
import api from "../../../../services";
import { enumPayment } from "../../../../utils/functions";
import ModalDetails from "./components/modal";
import * as S from "./style";

export function Web() {
  const { isMobileDevice } = useDevice();
  const [anchorEl, setAnchorEl] = useState(null);
  const [initialDateTime, setInitialDateTime] = useState(dayjs());
  const [finalydateTime, setFinalyDateTime] = useState(dayjs());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [statement, setStatement] = useState([]);
  const [data, setData] = useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [body, setBody] = useState({
    status: "",
    start: "",
    end: "",
  });

  function openModalDetails(row) {
    setData(row);
    setModalIsOpen(true);
    setAnchorEl(null);
  }

  async function getData() {
    const res = await api.post(`/v1/customers/finance`, body);

    setStatement(res.data.data);
    console.log(res.data.data);
  }

  useEffect(() => {
    getData();
  }, [body]);

  return (
    <S.Container>
      <S.Title>Extrato financeiro</S.Title>
      <S.Header>
        <S.TimeFilter>
          <S.Label>Data Inicial</S.Label>
          <TextField
            id="date"
            type="date"
            onChange={(e) => setBody({ ...body, start: e.target.value })}
            value={body?.start}
            inputProps={{
              style: {
                padding: 10,
                height: 20,
                marginRight: 10,
              },
            }}
          />
        </S.TimeFilter>
        <S.TimeFilter>
          <S.Label>Data Final</S.Label>
          <TextField
            id="date"
            type="date"
            onChange={(e) => setBody({ ...body, end: e.target.value })}
            value={body?.end}
            inputProps={{
              style: {
                padding: 10,
                height: 20,
                marginRight: 10,
              },
            }}
          />
        </S.TimeFilter>
      </S.Header>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#B2B2B2" }}>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: 700 }}>
                Data
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>
                Tipo
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>
                Detalhes
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>
                Status
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>
                Valor
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statement.map((statement) => (
              <TableRow
                key={statement.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row">
                  {statement?.date
                    ? statement.date.replace(/-/g, "/")
                    : statement.transaction_date.replace(/-/g, "/")}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {statement?.type}
                </TableCell>
                <TableCell align="center">
                  {statement?.type == "vendas"
                    ? `Transação: ${
                        statement?.transaction_code
                      } /  ${enumPayment(
                        statement?.payment_type
                      )}  | Taxa: ${new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                      }).format(parseFloat(statement?.tax_amount))}`
                    : null}
                  {statement?.type == "saques"
                    ? `Descrição: ${statement?.observation} | Número da conta de saque:  ${statement?.bank_account?.account}`
                    : null}
                  {statement?.type == "débitos"
                    ? `Descrição: ${statement?.observation}`
                    : null}
                </TableCell>
                <TableCell align="center">
                  <S.TextTableCell>
                    <S.Icon status={statement.status}>
                      <S.TextIcon status={statement.status}>
                        {statement.status}
                      </S.TextIcon>
                    </S.Icon>
                  </S.TextTableCell>
                </TableCell>
                <TableCell align="center">
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                  }).format(parseFloat(statement.amount))}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => openModalDetails(statement)}
                >
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    style={{ padding: 0 }}
                  >
                    <MoreVertOutlinedIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem>
                      <ZoomInOutlinedIcon style={{ marginRight: 5 }} />
                      Detalhes
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {modalIsOpen ? (
        <ModalDetails
          setModalIsOpen={setModalIsOpen}
          modalIsOpen={modalIsOpen}
          data={data}
        />
      ) : (
        ""
      )}
    </S.Container>
  );
}
