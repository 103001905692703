import * as React from "react";
import Box from "@mui/material/Box";
import * as S from "./style";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const style = {
  position: "absolute",
  borderRadius: "10px",
  width: `35%`,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,

  outline: `1px solid #d9d9d9`,
};

export default function ModalDetails({ modalIsOpen, setModalIsOpen, data }) {
  const handleClose = () => setModalIsOpen(false);
  return (
    <Modal
      open={modalIsOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <S.Icon>
          <CloseOutlinedIcon onClick={handleClose} />
        </S.Icon>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Detalhes da transação
        </Typography>
        <S.ContainerTitle>
          <S.Text>Status da transação:</S.Text>
          {data.status === "aprovado" ? (
            <S.IconGreen>
              <S.TextIconGreen>{data.status}</S.TextIconGreen>
            </S.IconGreen>
          ) : data.status === "reprovado" ? (
            <S.IconRed>
              <S.TextIconRed>{data.status}</S.TextIconRed>
            </S.IconRed>
          ) : data.status === "cancelado" ? (
            <S.IconBlack>
              <S.TextIconBlack>{data.status}</S.TextIconBlack>
            </S.IconBlack>
          ) : data.status === "pendente" ? (
            <S.IconYellow>
              <S.TextIconYellow>{data.status}</S.TextIconYellow>
            </S.IconYellow>
          ) : data.status === "estornado" ? (
            <S.IconBlack>
              <S.TextIconBlack>{data.status}</S.TextIconBlack>
            </S.IconBlack>
          ) : (
            ""
          )}
        </S.ContainerTitle>
        <S.Title>Informações do Comprador</S.Title>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <S.DivRow>
            <S.Text>Nome: {data?.client?.client_name}</S.Text>
            <S.Text>CPF: {data?.client?.client_document}</S.Text>
          </S.DivRow>
          <S.DivRow>
            <S.Text>Telefone: {data?.client?.client_phone}</S.Text>
            <S.Text>E-mail: {data?.client?.client_email}</S.Text>
          </S.DivRow>
          <S.Title>Endereço</S.Title>
          <S.DivRow>
            <S.Text>Rua: {data?.client?.address?.street}</S.Text>
            <S.Text>Cidade: {data?.client?.address?.city}</S.Text>
            <S.Text>Bairro: {data?.client?.address?.neighborhood}</S.Text>
          </S.DivRow>
          {!!data?.client?.credit_card ? (
            <>
              <S.Title>Cartão</S.Title>
              <S.DivRow>
                <S.Text>
                  Cartão: **** **** ****{" "}
                  {data?.client?.credit_card?.lastFourDigits}
                </S.Text>
                <S.Text>
                  Bandeira Cartão: {data?.client?.credit_card?.brand}
                </S.Text>
              </S.DivRow>
            </>
          ) : null}
        </Typography>
      </Box>
    </Modal>
  );
}
