import * as S from "./style";

import * as React from "react";

import { useNavigate } from "react-router-dom";

import walletIcon from "../../assets/images/wallet.svg";
import fiveLines from "../../assets/images/fiveLines.svg";
import CalenderIcon from "../../assets/images/CalenderIcon.svg";
import circleDolarIcon from "../../assets/images/circleDolar.svg";
import exitIcon from "../../assets/images/exitIcon.svg";
import profileIcon from "../../assets/images/profileIcon.svg";
import sendIcon from "../../assets/images/sendIcon.svg";
import profilePhoto from "../../assets/images/profilePhoto.png";
import threeLinesIcon from "../../assets/images/threeLines.svg";
import { GlobalContext } from "../../context/GlobalContext";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import api from "../../services";
import { CircularProgress } from "@mui/material";

export default function MobileLayout({ children }) {
  const { bankAccount, setBankAccount } = React.useContext(GlobalContext);
  const [userPhoto, setUserPhoto] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const navigate = useNavigate();
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  /*   const list = (anchor) => (
    
  ); */

  React.useEffect(() => {
    getBankInfos();
    getProfileData();
  }, []);

  async function getBankInfos() {
    try {
      const { data } = await api.get("v1/customers/bank_account");
      setBankAccount(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getProfileData() {
    try {
      setLoading(true);
      const { data } = await api.get("v1/customers/profile");
      setUserPhoto(data.photo);
      setName(data.name);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <S.Topbar>
            <Button onClick={toggleDrawer(anchor, true)}>
              <img src={threeLinesIcon} alt="Icone Carteira" />
            </Button>
          </S.Topbar>
          {children}
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            sx={{ widht: 1 }}
          >
            <Box
              sx={{ width: 1, height: 1 }}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <S.Container>
                <S.Navbar>
                  <S.Header>
                    {loading ? (
                      <S.ContainerLoading>
                        <CircularProgress style={{ color: "#FFF" }} />
                      </S.ContainerLoading>
                    ) : (
                      <>
                        <S.ProfileImage
                          src={userPhoto}
                          alt="profile"
                        ></S.ProfileImage>
                        <S.ProfileName>{name}</S.ProfileName>
                      </>
                    )}
                  </S.Header>
                  <S.ProfileData>
                    <S.Items>
                      <p>Agência: {bankAccount.agency}</p>
                    </S.Items>
                    <S.Items>
                      <p>Conta: {bankAccount.account}</p>
                    </S.Items>
                  </S.ProfileData>
                  <S.GroupItems>
                    <S.MenuItems
                      onClick={() => {
                        navigate("/home");
                      }}
                    >
                      <img src={walletIcon} width={25} alt="Icone Carteira" />
                      <p>Meu saldo</p>
                    </S.MenuItems>

                    <S.MenuItems
                      onClick={() => {
                        navigate("/extrato-financeiro");
                      }}
                    >
                      <img src={fiveLines} width={25} alt="Icone Carteira" />
                      <p>Extrato financeiro</p>
                    </S.MenuItems>

                    <S.MenuItems
                      onClick={() => {
                        navigate("/historico-de-transacoes");
                      }}
                    >
                      <img src={CalenderIcon} width={25} alt="Icone Carteira" />
                      <p>Histórico de transações</p>
                    </S.MenuItems>

                    {/* <S.MenuItems
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      <img src={sendIcon} width={25} alt="Icone Carteira" />
                      <p>Solicitações</p>
                    </S.MenuItems> */}

                    <S.MenuItems
                      onClick={() => {
                        navigate("/meu-perfil");
                      }}
                    >
                      <img
                        src={profileIcon}
                        width={25}
                        height={25}
                        alt="Icone Carteira"
                      />
                      <p>Meu Perfil</p>
                    </S.MenuItems>
                    <S.MenuItems>
                      <img
                        src={circleDolarIcon}
                        width={25}
                        alt="Icone Carteira"
                      />
                      <p>Tarifas e Prazos</p>
                    </S.MenuItems>
                    <S.MenuItems
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      <img src={exitIcon} width={25} alt="Icone Carteira" />
                      <p>Sair</p>
                    </S.MenuItems>
                  </S.GroupItems>
                </S.Navbar>
              </S.Container>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
