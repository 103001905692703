import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const Topbar = styled.div`
  padding: 10px 0px;
  background-color: #0092d4;
  display: flex;
  width: 100%;
`;
export const ContainerLoading = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Navbar = styled.div`
  background-color: #0092d4;
`;

export const ProfileImage = styled.img`
  width: 113px;
  height: 113px;
  border-radius: 50%;
  position: relative;
`;

export const ProfileName = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
`;
export const ProfileData = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 55px;
  cursor: pointer;

  p {
    margin-top: 7px;
    font-size: 25px;
    font-weight: 600;
    color: #fff;
  }
`;

export const Items = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  p {
    margin-top: 15px;
    font-size: 1rem;
    font-weight: 300;
    color: #fff;
  }
`;
export const MenuItems = styled.div`
  margin: 20px 0px 0px 20px;
  padding: 0px 0px 10px 0px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  p {
    margin-left: 10px;
    font-size: 1rem;
    font-weight: 300;
    color: #fff;
  }
`;

export const GroupItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const Content = styled.div`
  padding: 26px 44px 0 49px;
`;

export const ContentHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const Title = styled.p`
  font-size: 50px;
  font-weight: 400;
  color: #000;
  margin-top: 25px;
`;

export const Button = styled.button`
  width: 97px;
  height: 25px;
  border-radius: 10px;
  color: #fff;
  background-color: #000;
`;

export const Icon = styled.p``;
