import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";

import PersonalData from "./PersonalData";
import BankData from "./BankData";

import * as S from "./style";

const Tab = styled(TabUnstyled)`
  color: #333333;
  font-size: 1rem;
  font-weight: bold;
  padding: 6px 17px;
  border: none;
  background-color: #fff;
  border-bottom: 2px solid #fff;
  cursor: pointer;

  &.${tabUnstyledClasses.selected} {
    border-bottom: 2px solid #0092d4;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  padding: 0 25px;
`;

const TabsList = styled(TabsListUnstyled)`
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export default function Mobile() {
  return (
    <TabsUnstyled defaultValue={0}>
      <TabsList>
        <Tab>Dados pessoais</Tab>
        <Tab>Dados bancários</Tab>
      </TabsList>
      <TabPanel value={0}>
        <PersonalData />
      </TabPanel>
      <TabPanel value={1}>
        <BankData />
      </TabPanel>
    </TabsUnstyled>
  );
}
