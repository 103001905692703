import { useContext, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import api from "../../../../../services";
import * as S from './style'
import withReactContent from "sweetalert2-react-content";
import { GlobalContext } from "../../../../../context/GlobalContext";
import Swal from "sweetalert2";
import { cpfMask, cnpjMask } from "../../../../../utils/masks";
import { useNavigate } from "react-router-dom";

export default function BankData() {
  const MySwal = withReactContent(Swal)
  const navigate = useNavigate();
  const { bankAccount, setBankAccount } = useContext(GlobalContext)

  const [banks, setBanks] = useState([])

  useEffect(() => {
    getBanks()
  }, [])

  async function getBanks() {
    try {
      const { data } = await api.get('v1/customers/bank/list')
      setBanks(data)
    } catch (error) {
      console.log(error)
    }
  }

  function handleBankSelect(bank) {
    if (!bank) return
    setBankAccount(state => ({...state, bank_id: bank.id }))
  }

  function handleChange(e) {
    const {name, value} = e.target
    setBankAccount(state => ({ ...state, [name]: value }))
  }

  async function handleSaveButton(e) {
    e.preventDefault()
    try {
      await api.put('v1/customers/bank_account', bankAccount)
      MySwal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Dados bancarios salvos com sucesso!',
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  return(
    <S.Form>
      <S.Label>Banco:</S.Label>
      <Autocomplete
        disablePortal
        getOptionLabel={option => option.name || option.bank_name}
        getoptionselected={option => option.id}
        options={banks}
        noOptionsText="Banco não encontrado"
        onChange={(e, value) => handleBankSelect(value)}
        defaultValue={bankAccount}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Pesquise o banco..."
          />
        )}
        size="small"
      />
      
      <S.Label>Agência:</S.Label>
      <TextField
        fullWidth
        size="small"
        name="agency"
        onChange={handleChange}
        value={bankAccount.agency}
        placeholder="Dados da agência"
      />

      <S.Label>Conta Corrente:</S.Label>
      <TextField
        fullWidth
        size="small"
        name="account"
        onChange={handleChange}
        value={bankAccount.account}
        placeholder="Conta corrente"
      />

      <S.Label>CPF ou CNPJ do títular da conta:</S.Label>
      <TextField
        fullWidth
        size="small"
        name="document"
        onChange={handleChange}
        value={
          bankAccount.document.length > 13
            ? cnpjMask(bankAccount.document)
            : cpfMask(bankAccount.document)
        }
        placeholder="cpf do títular"
      />

      <S.Label>Nome do títular:</S.Label>
      <TextField
        fullWidth
        size="small"
        name="holder_name"
        onChange={handleChange}
        value={bankAccount.holder_name}
        placeholder="digite o nome do títular da conta"
        sx={{ marginBottom: '70px !important' }}
      />

      <S.ButtonWrapper>
        <S.Button onClick={handleSaveButton}>
          Salvar informações
        </S.Button>
        <S.OutlineButton>
          Cancelar
        </S.OutlineButton>
      </S.ButtonWrapper>
    </S.Form>
  )
}