export const cpfMask = (e) => {
  let value = e;
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
  return value;
}

export const phoneMask = (e) => {
  let value = e;

  value = value.replace(/\D/g, "");
  value = value
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
    .replace(/(-\d{4})\d+?$/, "$1");
  return value;
}

export const cnpjMask = (e) => {
  let value = e

  value = value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
  
  return value;
}

export const dateMask = value => {
  let v = value.replace(/\D/g,'').slice(0, 10);
  if (v.length >= 5) {
    return `${v.slice(0,2)}/${v.slice(2,4)}/${v.slice(4)}`;
  }
  else if (v.length >= 3) {
    return `${v.slice(0,2)}/${v.slice(2)}`;
  }
  return v
}

export const zipcodeMask = (e) => {
  let value = e
  
  value = value
    .replace(/\D/g,"")                
    .replace(/^(\d{5})(\d)/,"$1-$2") 
  
  return value
  
  }