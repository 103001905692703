import styled from "styled-components";

export const Container = styled.div`
  margin: 20px;
`;

export const HeaderAndFilter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 26px;
  color: #333;
`;

export const ContainerFilter = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 5px;
  background-color: #0092d4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const TextFilter = styled.span`
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #fff;
`;

export const Content = styled.div`
  margin: 30px 0px;
`;
export const ContainerAccordion = styled.div`
  margin: 20px 0px;
`;

export const HeaderAccordion = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TitleAccordion = styled.span`
  color: #0092d4;
  font-weight: 700;
  font-size: 16px;
`;

export const Text = styled.div`
  margin-top: 10px;
  color: #333;
  font-weight: 700;
  font-size: 12px;
`;

export const InformationsAccordion = styled.span`
  color: #333;
  font-weight: 500;
  font-size: 12px;
  margin-top: 5px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  max-width: ${(props) =>
    props.status === "aprovado"
      ? "92%"
      : props.status === "pendente"
      ? "92%"
      : "100%"};
`;

export const Value = styled.div`
  font-weight: 700;
  font-size: 1rem;
  color: ${(props) =>
    props.status === "aprovado"
      ? "#00ba07"
      : props.status === "reprovado"
      ? "#E93E3E"
      : "#333333"};
`;

export const TextIcon = styled.span`
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: ${(props) =>
    props.status === "aprovado"
      ? "#28c76f"
      : props.status === "reprovado"
      ? "#E93E3E"
      : props.status === "estornado"
      ? "#E93E3E"
      : props.status === "cancelado"
      ? "#333333"
      : "#FAAB36"};
`;

export const Icon = styled.div`
  width: 90px;
  height: 20px;
  border-radius: 17px;
  padding: 1px 9px;
  background-color: ${(props) =>
    props.status === "aprovado"
      ? "#28c76f20"
      : props.status === "reprovado"
      ? "#E93E3E20"
      : props.status === "estornado"
      ? "#E93E3E20"
      : props.status === "cancelado"
      ? "#33333320"
      : "#FAAB3620"};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextAccordion = styled.span`
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #fd5454;
  /* color: ${(props) => (props.status === "reprovado" ? "#fd5454" : "#333")} */
`;
