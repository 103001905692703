export const initialDataForm = {
  photo: '',
  name: '',
  email: '',
  phone: '',
  document: '',
  address: {
    zip_code: '',
    state: '',
    city: '',
    street: '',
    number: '',
    neighborhood: '',
    complement: ''
  }
}