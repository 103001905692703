import styled from "styled-components"

export const Form = styled.form`
  .MuiTextField-root {
    margin-bottom: 20px;
  }
`

export const Label = styled.label`
  display: block;
  margin-bottom: 7px;
  color: #333333;
  font-size: 18px;
`

export const ButtonWrapper = styled.div``

export const Button = styled.button`
  width: 100%;
  height: 42px;
  background-color: #01b797;
  color: #fff;
  border-radius: 10px;
  border: none;
  font-size: 17px;
  font-weight: 500;
  padding: 10px 17px;
`

export const OutlineButton = styled.button`
  width: 100%;
  height: 42px;
  background-color: #fff;
  color: #01b797;
  border-radius: 10px;
  border: 1px solid #01b797;
  font-size: 17px;
  font-weight: 500;
  padding: 10px 17px;
  margin-top: 30px;
  margin-bottom: 64px;
`