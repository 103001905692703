import styled from "styled-components";

export const Box = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 600px;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fff;

  @media (min-width: 590px) {
    width: 100%;
  }
`;

export const Container = styled.div``;

export const Title = styled.p`
  position: relative;
  font-size: 22px;
  color: #000;
  margin-bottom: 15px;

  svg {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
`;

export const Subtitle = styled.p`
  font-size: 1rem;
  color: #636363;
  margin-bottom: 20px;
`;

export const Form = styled.form`
  margin-bottom: 27px;

  .MuiTextField-root {
    margin-bottom: 20px;
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 7px;
  color: #6e6b7b;
  font-size: 13px;
  font-weight: 400;
`;

export const ButtonWrapper = styled.div`
  margin-top: 30px;
`;

export const Button = styled.button`
  width: 100%;
  height: 42px;
  background-color: #01b797;
  color: #fff;
  border-radius: 10px;
  border: none;
  font-size: 17px;
  font-weight: 500;
  padding: 10px 17px;

  @media (min-width: 507px) {
    width: 200px;
  }
`;

export const OutlineButton = styled.button`
  width: 100%;
  height: 42px;
  background-color: #fff;
  color: #01b797;
  border-radius: 10px;
  border: 1px solid #01b797;
  font-size: 17px;
  font-weight: 500;
  padding: 10px 17px;
  margin-top: 18px;

  @media (min-width: 507px) {
    width: 200px;
    margin-left: 16px;
    margin-top: 0;
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 1rem;
`;
