import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import sucess from "../../../../assets/images/sucess.png";
import * as S from "./style";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "@media (min-width:768px)": {
    width: "50%",
  },
  width: "90%",
  bgcolor: "#FFF",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  padding: "20px",
};

export default function ModalSucess({ setOpenModalSucess, openModalSucess }) {
  const handleClose = () => setOpenModalSucess(false);

  return (
    <div>
      <Modal
        open={openModalSucess}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <S.Header>
            <S.Icon>
              <CloseIcon
                size={32}
                color="#333333"
                weight="bold"
                onClick={handleClose}
              />
            </S.Icon>
          </S.Header>
          <S.Content>
            <S.GroupHeader>
              <img src={sucess} width={75} />
            </S.GroupHeader>
            <S.TitleSecondary>
              Solicitação enviada com sucesso!
            </S.TitleSecondary>

            <S.ContainerButton>
              <S.ButtonOutlineSecondary onClick={handleClose}>
                ok
              </S.ButtonOutlineSecondary>
            </S.ContainerButton>

            <S.Label>
              Esta operação pode levar até 24 horas para ser processada. Para
              maiores informações nossas tarifas e taxas
              <a href="www.google.com" target="__blank">
                clicando aqui
              </a>
            </S.Label>
          </S.Content>
        </Box>
      </Modal>
    </div>
  );
}
